import {createFeature, createReducer, on} from '@ngrx/store';
import {StudentLexileInfo} from '../../models/student-lexile-info';
import {PowerTextModeActions, StudentLexileColorActions, UniversalScreenerActions, UpdatePasswordActions, UpdateUserActions, UserActions} from './user.actions';
import {CallState} from '../../../../../../consumer/src/app/models/enums/call-state';
import {ApiResponse} from '../../models/api-response';
import {UserData} from '../../models/user-data';
import {UpdateUserModel} from '../../models/update-user-model';
import { IUniversalScreenerStatusModel } from '../../models/universal-screener-model';

export const userFeatureKey = 'user';

export interface State {
  userData: ApiResponse<UserData>;
  studentLexileInfo: StudentLexileInfo;
  isInPowerTextMode: ApiResponse<boolean>;
  updateUser: ApiResponse<UpdateUserModel>;
  updatePassword: ApiResponse<null>;
  universalScreenerStatus: ApiResponse<IUniversalScreenerStatusModel>;
}

export const initialState: State = {
  userData: {data: null, callState: CallState.Initial, error: null},
  studentLexileInfo: null,
  isInPowerTextMode: {data: false, callState: CallState.Initial, error: null},
  updateUser: {data: null, callState: CallState.Initial, error: null},
  updatePassword: {data: null, callState: CallState.Initial, error: null},
  universalScreenerStatus: { data: null, error: null, callState: CallState.Initial }
};

export const reducer = createReducer(
  initialState,
  on(UserActions.setupUserData, state => ({...state, userData: {...state.userData, callState: CallState.Loading}})),
  on(UserActions.setupUserDataSuccess, (state, action) => ({...state, userData: {...state.userData, data: action.data, callState: CallState.Loaded}})),
  on(UserActions.setupUserDataFailure, (state, action) => ({...state, callState: CallState.Error})),
  on(
    StudentLexileColorActions.loadStudentLexileColorSuccess,
    (state, { thresholdLevel, badgeType }) => ({
      ...state,
      studentLexileInfo: {
        thresholdLevel,
        badgeType,
      },
    })
  ),
  on(PowerTextModeActions.loadPowerTextModeSuccess, (state, action) => ({...state, isInPowerTextMode: {data: action.isInPowerTextMode, callState: CallState.Loaded, error: null}})),
  on(PowerTextModeActions.loadPowerTextModeFailure, (state, action) => ({...state, isInPowerTextMode: {data: null, callState: CallState.Error, error: action.error}})),

  on(UpdateUserActions.updateUser, state => ({...state, updateUser: {data: null, callState: CallState.Loading, error: null}})),
  on(UpdateUserActions.updateUserSuccess, (state, action) => (
    {
      ...state,
      updateUser: {data: action.request, callState: CallState.Loaded, error: null},
      userData: {...state.userData, data: {...state.userData.data, teacher: {...state.userData.data.teacher,
            firstName: action.request.firstName || state.userData.data.teacher.firstName,
            lastName: action.request.lastName || state.userData.data.teacher.lastName,
      }}}
    }
  )),
  on(UpdateUserActions.updateUserFailure, (state, action) => ({...state, updateUser: {data: null, callState: CallState.Error, error: action.error}})),

  on(UpdatePasswordActions.updatePassword, state => ({...state, updatePassword: {data: null, callState: CallState.Loading, error: null}})),
  on(UpdatePasswordActions.updatePasswordSuccess, state => ({...state, updatePassword: {data: null, callState: CallState.Loaded, error: null}})),
  on(UpdatePasswordActions.updatePasswordFailure, (state, action) => ({...state, updatePassword: {data: null, callState: CallState.Error, error: action.error}})),

  on(UniversalScreenerActions.loadUniversalScreenerStatus, state => ({ ...state, universalScreenerStatus: { callState: CallState.Loading, data: null, error: null } })),
  on(UniversalScreenerActions.loadUniversalScreenerStatusSuccess, (state, action) => ({ ...state, universalScreenerStatus: { data: action.data, callState: CallState.Loaded, error: null } })),
  on(UniversalScreenerActions.loadUniversalScreenerStatusFailure, (state, action) => ({ ...state, universalScreenerStatus: { data: null, error: action.error, callState: CallState.Error } })),
  on(UniversalScreenerActions.startUniversalScreener, (state, action) => ({ ...state, universalScreenerStatus: { callState: CallState.Initial, data: null, error: null } })),
);

export const userFeature = createFeature({
  name: userFeatureKey,
  reducer,
});

